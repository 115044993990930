/* You can add global styles to this file, and also import other style files */

@use 'styles/bootstrap-lite.scss';
@use 'styles/theme.scss';
@use 'styles/snackbar.scss';
@use 'variables.module' as *;
@tailwind base;
@tailwind components;
@tailwind utilities;
// @use 'ngx-sharebuttons/themes/material';


.full-width-tooltip .mdc-tooltip__surface {
  max-width: unset !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: $theme-background !important;
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (2500 - 300)));
}

fa-icon svg {
  display: inline-block;
  font-size: inherit;
  height: 1em;
}

fa-icon .fa-2x {
  height: 2em;
}

a {
  color: $theme-primary;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

hr {
  opacity: 1;
}

.mat-content {
  justify-content: center;
}

.mat-content>mat-panel-title,
.mat-content>mat-panel-description {
  flex: 0 0 auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-drawer-container {
  background-color: $theme-background !important;
}

.mdc-notched-outline__notch {
  border-right: none;
}