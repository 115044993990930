@import "../_variables.module.scss";
@tailwind components;

@layer components {
  .mat-mdc-snack-bar-container {
    &.mat-snack-success {
      --mat-mdc-snack-bar-button-color: #fff;
      --mdc-snackbar-supporting-text-color: #{$theme-oxylio-main-font};
      --mdc-snackbar-container-color: #{$theme-oxylio-background-success};
      border-color: #{$theme-oxylio-element-grey-darken};
    }

    // warning style orange
    &.mat-snack-warning {
      --mat-mdc-snack-bar-button-color: #fff;
      --mdc-snackbar-supporting-text-color: #{$theme-oxylio-main-font};
      --mdc-snackbar-container-color: #{$theme-oxylio-background-warning};
      border-color: #{$theme-oxylio-element-grey-darken};
    }

    // alert style rouge
    &.mat-snack-alert {
      --mat-mdc-snack-bar-button-color: #fff;
      --mdc-snackbar-supporting-text-color: #{$theme-oxylio-main-font};
      --mdc-snackbar-container-color: #{$theme-oxylio-background-element-warning};
      border-color: #{$theme-oxylio-element-grey-darken};
    }

    // neutral style white
    &.mat-snack-neutral {
      --mat-mdc-snack-bar-button-color: #fff;
      --mdc-snackbar-supporting-text-color: #{$theme-oxylio-main-font};
      --mdc-snackbar-container-color: #{$theme-background-light};
      border-color: #{$theme-oxylio-element-grey-darken};
    }
  }
}
