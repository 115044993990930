$theme-color-black: #333333; // add in oxylio theme
$theme-color-grey: #acacac; // add in oxylio theme

$theme-oxylio-white-font: white;
$theme-oxylio-background-element-white: white;

// ----------  theme Oxylio  ----------
// Elements color 
$theme-oxylio-Main-blue: #004c97;
$theme-oxylio-secondary-blue-light: #26A8ED;

//----------------------------------------------------------------------------------------------------------------

// Material primary color
$theme-primary: #004c97;
// Material background color
$theme-background: #f3f3f3;
$theme-background-light: white;
$theme-background-dark: #e1e2e1;
// Material primary text color
$theme-dark-primary-text: rgba(black, 0.87);
$theme-light-primary-text: white;

// ---------------------------------------------------------

.theme-color-light {
    color: $theme-primary;
}

$theme-oxylio-background-mobile: rgba(38, 168, 237, 0.18); // blue ultra light for: background in mobile view in table
$theme-oxylio-background-element-warning: #FFCCCC;
$theme-oxylio-background-element-black: black; // for background rgpd banner
$theme-oxylio-background-picture-counter: rgba(255, 255, 255, 0.65); // background color of counter picture vehicule card
$theme-oxylio-background-warranty-page: rgba(129, 213, 242, 0.18);
$theme-oxylio-background-success: #D4EDD9; // background snackbar
$theme-oxylio-background-warning: #FFF3CD; // background snackbar
$theme-oxylio-element-grey-darken: #dedede; // grey light for: border
$theme-oxylio-green-light: #21DAA5; // green light

$theme-oxylio-arrow-down: #00A211; // gree fluo for: low price car in comparator
$theme-oxylio-print-icon: #6c4e9b; // for print icon in product page

// font color
$theme-oxylio-main-font: #323C48; // grey-darken for: title page, title element...
$theme-oxylio-secondary-font: #acacac; // grey for: subtitle
$theme-oxylio-warning-font: #EB2553;
$theme-oxylio-secondary-warning-font: #A50E06;

// for statique page : Keystone
$theme-oxylio-element-orange: #ffcc33; // for statique page
$theme-oxylio-blacklink: #444; // for link static page
$theme-oxylio-color-p-span: #757575; // for static page
$theme-oxylio-color-strong-b: #212121; // for static page


// box shadow
$theme-oxylio-box-shadow-2: rgba(0, 0, 0, 0.2);
$theme-oxylio-box-shadow-4: rgba(0, 0, 0, 0.04);
$theme-oxylio-box-shadow-9: rgba(0, 0, 0, 0.09);
$theme-oxylio-box-shadow-12: rgba(0, 0, 0, 0.12);
$theme-oxylio-box-shadow-14: rgba(0, 0, 0, 0.14);

// gradient for CO2 shema in fuel consumption
$theme-oxylio-gradient-green-darken: #008738;
$theme-oxylio-gradient-green: #49a830;
$theme-oxylio-gradient-green-lighten: #b0d718;
$theme-oxylio-gradient-yellow: #f9f808;
$theme-oxylio-gradient-orange: #f9b009;
$theme-oxylio-gradient-red-lighten: #f85009;
$theme-oxylio-gradient-red: #f80000;

// Shared/components/vehicule-card/flags
// linear-gradient : PRIME 
$theme-oxylio-gradient-prime-green: #00E689;

// linear-gradient : PROMO 
$theme-oxylio-gradient-promo-yellow: #E6EB25;
$theme-oxylio-gradient-promo-orange-darken: #FFBB33;

/////////////// a valider/ définir avec MAx
// $theme-color-grey: #acacac; c'est pour les sous titres
// $theme-oxylio-background-element-warning: #FFCCCC;
// $theme-oxylio-element-grey-darken: #dedede;

// definir un theme avec material, remplacer tte les couleur primary de Material par notre bleue


// -------------------------------------

// screen size
$screen-xsmall: 480px;
$screen-small: 768px;
$screen-medium: 992px;
$screen-large: 1200px;